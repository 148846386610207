body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* VerticalMenu.css */
.vertical-menu {

  display: flex;
  flex-direction: column;
  padding: 0;
}

.menu-item {
  color: black;
  padding: 15px;
  text-decoration: none;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  border-bottom: #dedede solid 1px;
}

.menu-item:hover {

  color: black;
}

header {
  height: 70px;
  width: 100vw;
  padding: 0px 0px 0px 10px;
  background-color: #ffffff;
  position: fixed;
  z-index: 100;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 27px;
  font-weight: 600;
  color: rgb(47, 141, 70);
}

.icn {
  height: 30px;
}

.menuicn {
  cursor: pointer;
}

.logosec {
  gap: 60px;
}

.main-container {
  display: flex;
  width: 100vw;
  position: relative;
  top: 70px;
  z-index: 1;
}

.main {
  height: calc(100vh - 70px);
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 40px 30px 30px 30px;
}

.main::-webkit-scrollbar-thumb {
  background-image:
    linear-gradient(to bottom, rgb(0, 0, 85), rgb(0, 0, 50));
}

.main::-webkit-scrollbar {
  width: 5px;
}

.main::-webkit-scrollbar-track {
  background-color: #9e9e9eb2;
}

.nav {
  min-height: 91vh;
  width: 250px;
  background-color: var(--background-color2);
  position: absolute;
  top: 0px;
  left: 00;
  box-shadow: 1px 1px 10px rgba(198, 189, 248, 0.825);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding: 30px 0 20px 10px;
}

.navcontainer {
  height: calc(100vh - 70px);
  width: 250px;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #ffffff;
  border-right: #dddddd solid 1px !important;
}

.navcontainer::-webkit-scrollbar {
  display: none;
}

.form-select,
.form-control {
  border: #575757 solid 1px !important;
  border-radius: 3px !important;
  padding: 8px 10px !important;
  font-size: 13px !important;
}

.form-label {
  font-size: 14px;
  font-weight: 600;
}

.btn-primary {
  background: #0eacd4 !important;
  border-radius: 3px !important;
  padding: 8px 10px !important;
  border: none;
  font-size: 13px !important;
}

.card {
  border-radius: 0px !important;
}

.card-header h2 {
  font-size: 16px;
  margin-bottom: 0px;
}

.userName {
  border-width: 1px;
  border-color: rgb(132, 215, 72);
  border-style: solid;
  border-radius: 3px;
  padding: 5px 10px;
  background-color: rgb(215, 253, 188);

}

.UsernameWrp {
  margin: 0px;
  float: right;
  margin-right: 10px;
}

.UsernameWrp ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.UsernameWrp ul li {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: inline-block;
  font-size: 14px;
  margin-left: 10px;
}

.UsernameWrp ul li a {
  cursor: pointer;
}


.btn-primary {
  background: #0eabd4;
  border: none !important;
}

.btn-primary:hover {
  background: #0993b7;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.43);
}